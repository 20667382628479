//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "SizeInternational",
    mixins: [
        breakpointsListMixin,
    ],

    data: function () {
        return {
            tableData: {
                head: {
                    centimeter: ["DE","UK","US","FR","IT"]
                },
                body: {
                    centimeter: [
                        ["65",  "30", "30", "80", "0"],
                        ["70",  "32", "32", "85", "1"],
                        ["75",  "34", "34", "90", "2"],
                        ["80",  "36", "36", "95", "3"],
                        ["85",  "38", "38", "100", "4"],
                        ["90",  "40", "40", "105", "5"],
                        ["95",  "42", "42", "110", "6"],
                        ["100", "44", "44", "115", "7"],
                        ["105", "46", "46", "120", "8"],
                        ["110", "48", "48", "125", "9"],
                        ["115", "50", "50", "130", "10"],
                    ]
                }
            }
        }
    },

    computed: {
        tableHeadData() {
            return this.tableData.head.centimeter
        },
        tableBodyData() {
            return this.tableData.body.centimeter
        },
        isMobile() {
            return this.mobileDown;
        },
        windowWidth() {
            return process.browser ? document.documentElement.clientWidth : 320;
        },
        isTable() {
            return this.tabletDown;
        },
        maxWidth() {
            return ((this.isMobile || this.isTable) ? (this.windowWidth - 40) : 680) + "px";
        }
    }

}
