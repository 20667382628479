//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "SizeLetter",
    mixins: [
        breakpointsListMixin,
    ],
    data: function () {
        return {
            tableData: {
                head: {
                    centimeter: [this.$t('sizeTable.sizeBra.columnLabel'), "A", "B", "C", "D", "E", "F", "G", "H"]
                },
                body: {
                    centimeter: [
                        ["65", "2XS", "XS", "XS", "XS", "XS", "S", "S", "M"],
                        ["70", "XS", "XS", "S", "S", "S", "M", "M", "M"],
                        ["75", "S", "S", "S", "M", "M", "L", "L", "L"],
                        ["80", "M", "M", "M", "L", "L", "L", "L", "XL"],
                        ["85", "L", "L", "L", "XL", "XL", "XL", "XL", "XL"],
                        ["90", "XL", "XL", "XL", "XL", "1X", "1X", "1X", "1X"],
                        ["95", "1X", "1X", "1X", "1X", "2X", "2X", "2X", "2X"],
                        ["100", "2X", "2X", "2X", "2X", "3X", "3X", "3X", "3X"],
                        ["105", "3X", "3X", "3X", "3X", "4X", "4X", "4X", "4X"],
                        ["110", "4X", "4X", "4X", "4X", "4X", "4X", "4X", "4X"],
                    ]
                }
            }
        }
    },
    methods: {},
    computed: {
        tableHeadData() {
            return this.tableData.head.centimeter
        },
        tableBodyData() {
            return this.tableData.body.centimeter
        },
        isMobile() {
            return this.mobileDown;
        },
        windowWidth() {
            return process.browser ? document.documentElement.clientWidth : 320;
        },
        isTable() {
            return this.tabletDown;
        },
        maxWidth() {
            return ((this.isMobile || this.isTable) ? (this.windowWidth - 40) : 680) + "px";
        }
    }

}
