//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "SizeLetterPlus",
    mixins: [
        breakpointsListMixin,
    ],
    data: function () {
        return {
            tableData: {
                head: {
                    centimeter: [this.$t('sizeTable.sizeBra.columnLabel'), "A", "B", "C", "D", "E", "F", "G"]
                },
                body: {
                    centimeter: [
                        ["60","-","XXS A-C","XXS A-C","XXS A-C","XXS D-DD","XXS D-DD","XXS D-DD"],
                        ["65","XXS A-C","XXS A-C","XXS A-C/XS A-C","XXS D-DD/XS A-C","XXS D-DD/XS D-DD","XS D-DD","XS D-DD"],
                        ["70","XS A-C","XS A-C","XS A-C","XS D-DD","XS D-DD","XS D-DD/S D-DD","S D-DD"],
                        ["75","S A-C","S A-C","S A-C","S D-DD","S D-DD","S D-DD","M D-DD"],
                        ["80","S A-C/M A-C","M A-C","M A-C","M A-C/M D-DD","M D-DD","M D-DD","M D-DD"],
                        ["85","M A-C","L A-C","L A-C","L A-C/L D-DD","L D-DD","L D-DD","L D-DD"],
                        ["90","L A-C","L A-C","XL A-C","L D-DD","L D-DD/XL D-DD","XL D-DD","XL D-DD"],
                        ["95","XL A-C","XL A-C","XL A-C/XXL A-C","XL D-DD","XL D-DD","XL D-DD/XXL D-DD","XXL D-DD"],
                        ["100","XL A-C","XXL A-C","XXL A-C","XL A-C/XXL D-DD","XXL D-DD","XXL D-DD","-"],
                    ]
                }
            }
        }
    },
    methods: {},
    computed: {
        tableHeadData() {
            return this.tableData.head.centimeter
        },
        tableBodyData() {
            return this.tableData.body.centimeter
        },
        isMobile() {
            return this.mobileDown;
        },
        windowWidth() {
            return process.browser ? document.documentElement.clientWidth : 320;
        },
        isTable() {
            return this.tabletDown;
        },
        maxWidth() {
            return ((this.isMobile || this.isTable) ? (this.windowWidth - 40) : 680) + "px";
        }
    }

}
