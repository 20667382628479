//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "../../../../common/ResponsiveImage";
import AnchorsList from "./components/AnchorsList/Index";
import SizeLetterPlus from "./components/SizeTable/SizeLetterPlus.vue";
import SizeLetter from "~/components/popups/SizeTable/components/SizeBra/components/SizeTable/SizeLetter.vue";
import SizeInternational
    from "~/components/popups/SizeTable/components/SizeBra/components/SizeTable/SizeInternational.vue";

export default {
    name: "SizeBra",
    data: function() {
        return {
            bootsMaterialImage: StaticImages[`sizeTableBootsMaterialBlock_${this.$i18n.locale}`],
            bootsComfortableImage: StaticImages[`sizeTableBootsComfortableBlock_${this.$i18n.locale}`],
        }
    },
    computed: {
        imageSrc() {
            return this.isWomenSwimsuits ? this.swimsuitsImage : this.clothesImage;
        },
        l () {
            return this.$i18n.locale
        }
    },
    methods: {
        scrollTo(el) {
            return {
                el: el,
                container: '.v-popup__container__body',
                duration: 300,
                lazy: false,
                easing: 'linear',
                cancelable: true,
                x: false,
                y: true
            }
        },
        imageConfig(url, title = "", alt = "") {
            return {
                src: {
                    desktop: {
                        '1x': url,
                        '2x': url,
                    },
                    tablet: {
                        '1x': url,
                        '2x': url,
                    },
                    mobile: {
                        '1x': url,
                        '2x': url,
                    },
                },
                title: title,
                alt: alt,
            }
        },
    },
    components: {SizeInternational, SizeLetter, SizeLetterPlus, AnchorsList, ResponsiveImage},
}
